import { HttpClient } from '@angular/common/http';
import { TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { MessageComponent } from '../components/_layout/messages/message/message.component';
import { LxmDialog } from '../helpers/dialogs';
import {
  HttpBadRequestResponse,
  HttpUnauthorizedResponse,
  IErrorValue,
} from '../helpers/http/BadRequest';
import { LxmMessage } from '../helpers/messages';

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    result(
      form: FormGroup,
      handler: (value: any) => void,
      errHandler?: (
        error: HttpBadRequestResponse | HttpUnauthorizedResponse,
      ) => void,
      actionName?: string,
      actionLoading?: any,
      message?: IResultMessage,
      confirm?: IResultConfirm,
    ): Subscription;
  }
}

interface IResultMessage {
  LxmMessage: LxmMessage;
  successMessage: string;
  errorMessage: string;
}

interface IResultConfirm {
  LxmDialog: LxmDialog;
  image: string;
  template: string;
  templateRef?: TemplateRef<any>;
}

Observable.prototype.result = function (
  form: FormGroup,
  handler: (value: any) => void,
  errHandler?: (
    error: HttpBadRequestResponse | HttpUnauthorizedResponse,
  ) => void,
  actionName?: string,
  actionLoading?: any,
  message?: IResultMessage,
  confirm?: IResultConfirm,
): Subscription {
  const handleResult = () => {
    if (message) {
      var { LxmMessage, successMessage, errorMessage } = message;
    }

    if (actionLoading) {
      actionLoading[actionName] = true;
    }

    const o = this as Observable<any>;
    return o.subscribe(
      (value) => {
        handler(value);
        if (LxmMessage && successMessage) {
          LxmMessage.ok({
            message: successMessage,
          });
        }

        if (actionLoading) {
          actionLoading[actionName] = false;
        }
      },
      (error) => {
        if (error instanceof HttpBadRequestResponse) {
          if (form) {
            const validationErrors: { [key: string]: IErrorValue[] } = {};
            for (const i in error.validationResults) {
              if (error.validationResults.hasOwnProperty(i)) {
                const c = form.get(i);
                if (c) {
                  c.setErrors(error.validationResults[i]);
                }
                validationErrors[i] = error.validationResults[i];
              }
            }
            form.setErrors(validationErrors);
          }
        } else if (error instanceof HttpUnauthorizedResponse) {
          error.validationSummary = `global.errors.${error.validationSummary}`;
        } else {
          const newError = new HttpBadRequestResponse();
          newError.exception = error;
          newError.validationSummary = 'common.message.request_failed';
          error = newError;
        }

        if (errHandler) {
          errHandler(error);
        }

        if (LxmMessage) {
          let message = errorMessage ? errorMessage : error?.validationSummary;
          let messageArgs: any;

          if (!message) {
            const firstValidationError = Object.values(form.errors)?.[0]?.[0];
            if (firstValidationError) {
              const { value, args } = firstValidationError || {};
              if (value) {
                message = value;
                if (args) {
                  messageArgs = args;
                }
              }
            }
          }

          LxmMessage.error({
            message: message ?? 'global.generic_error',
            args: messageArgs,
          });
        }

        if (actionLoading) {
          actionLoading[actionName] = false;
        }
      },
    );
  };

  if (confirm) {
    var { LxmDialog, image, template, templateRef } = confirm;
    LxmDialog.confirm(
      {
        image: image,
        template: template,
        templateRef: templateRef,
      },
      (res) => {
        if (res) {
          return handleResult();
        }
        return new Subscription();
      },
    );
  } else {
    return handleResult();
  }

  return new Subscription();
};
