import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes, UserSettingsResolve } from './app.routes';

import { DatePipe, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAngularSvgIcon, SvgIconComponent } from 'angular-svg-icon';
import { apiInterceptor } from './helpers/http/api.interceptor';
import { errorInterceptor } from './helpers/http/error.interceptor';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// EXTENSIONS
import 'src/app/ext/observable-result';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { PaginatorIntlService } from './services/paginator-intl.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LocaleService } from './services/locale.service';
import { PriceValuePipe } from './pipes/price-value.pipe';

import localeEt from '@angular/common/locales/et';
import localeEn from '@angular/common/locales/en';
registerLocaleData(localeEt, 'et-EE');
registerLocaleData(localeEn, 'en-US');

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    // provideExperimentalZonelessChangeDetection(),
    DatePipe,
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'et',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptors([apiInterceptor, errorInterceptor])),
    provideAngularSvgIcon(),
    provideRouter(routes),
    provideAngularSvgIcon(),
    TranslateModule,
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService],
    },
    LocaleService,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    UserSettingsResolve,
  ],
};
