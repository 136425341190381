<div
  class="f-1 flex h-[3.125rem] items-center justify-between bg-inherit py-2x"
>
  <!-- LOGO -->
  <div class="flex cursor-pointer" routerLink="/">
    <svg-icon
      src="assets/icons/lxm-logo.svg"
      alt="logo"
      [svgStyle]="{ 'width.rem': 1.375, 'height.rem': 1.625 }"
      viewBox="auto"
    ></svg-icon>
  </div>

  <!-- ACTIONS -->
  @switch (appState.isVisitor) {
    @case (false) {
      <div class="flex items-center justify-center gap-5x">
        @for (item of actionItems; track item.title) {
          <ng-container
            *ngTemplateOutlet="
              headerActionButtonTemplate;
              context: { item: item }
            "
          ></ng-container>
        }
      </div>
    }

    @case (true) {
      <div class="flex items-center justify-center gap-5x">
        @for (item of visitorActionItems; track item.title) {
          <ng-container
            *ngTemplateOutlet="
              headerActionButtonTemplate;
              context: { item: item }
            "
          ></ng-container>
        }
      </div>
    }
  }
</div>

<ng-template #headerActionButtonTemplate let-item="item">
  <button
    matRipple
    class="flex size-15x items-center justify-center rounded-full bg-grey-100"
    (click)="item.function ? item.function() : undefined"
  >
    <svg-icon
      src="assets/icons/menu/{{ item.icon }}"
      [svgStyle]="{ 'width.rem': 1.875, 'height.rem': 1.875 }"
      viewBox="auto"
    ></svg-icon>
  </button>
</ng-template>

@let textClasses = "font-display text-sm font-bold text-grey-100";

<!-- @let linkClasses =
  "flex min-h-big cursor-pointer items-center gap-5x px-7x py-4x hover:bg-veryLightPeach"; -->

<!-- USER MENU CONTENT -->
<ng-template #userHeaderTemplate>
  <!-- USER ICON -->
  <svg-icon src="assets/icons/menu/menu-user.svg"></svg-icon>
  <!-- USER NAME -->
  <span [class]="textClasses"> {{ appState.user?.name }} </span>
</ng-template>
<ng-template #userContentTemplate>
  <section class="pb-4x">
    <h5>Konto</h5>
    <div class="py-4x">
      <div class="navigator-menu-tenants" [ngSwitch]="tenants.length > 1">
        <ng-container *ngSwitchCase="true" [formGroup]="form">
          <app-select
            class="flex min-h-big cursor-pointer items-center gap-12x border-none px-2x text-grey-100 hover:bg-veryLightPeach"
            for="tenant"
            [options]="tenants"
            [searchable]="false"
            value="id"
            [clearable]="false"
            (change)="switchTenant($event.id)"
          >
          </app-select>
        </ng-container>
        <ng-container *ngSwitchDefault>
          @for (tenant of tenants; track $index) {
            <a
              ov-menu-item
              [label]="tenant.name"
              [class.current]="tenant.id === tenantId"
              (click)="switchTenant(tenant.id)"
              tabIndex="-1"
            >
            </a>
          }
        </ng-container>
      </div>
    </div>
    <hr class="-mx-8x text-grey-30" />
  </section>
  <section class="pb-4x">
    <h5>Konto seaded</h5>
    <div class="py-4x">
      <app-select
        class="flex min-h-big cursor-pointer items-center gap-12x border-none px-2x text-grey-100 hover:bg-veryLightPeach"
        [formGroup]="form"
        for="language"
        [options]="languageOptions"
        [searchable]="false"
        [clearable]="false"
        compareField="id"
        (change)="changeLanguage($event.id)"
        labelField="name"
        labelIconField="name"
      ></app-select>
      <a ov-menu-item routerLink="/user/settings"> Kasutaja seaded </a>
    </div>
    <hr class="-mx-8x text-grey-30" />
  </section>
  <section class="pb-4x">
    <div class="py-4x">
      <a ov-menu-item (click)="logout()">Logi välja</a>
    </div>
  </section>
</ng-template>

<!-- HELP MENU CONTENT -->
<ng-template #helpHeaderTemplate>
  <!-- HELP ICON -->
  <svg-icon src="assets/icons/menu/menu-question.svg"></svg-icon>
  <!-- HELP TEXT -->
  <span [class]="textClasses"> ? </span>
</ng-template>
<ng-template #helpContentTemplate> Midagi siin </ng-template>
