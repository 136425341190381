import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { formUtil } from 'src/app/util/form-util';
import moment from 'moment';
import { TitleService } from 'src/app/services/title.service';
import { StoreState } from '../../../e-store/store.state';
import { CardComponent } from 'src/app/components/_layout/card/card.component';
import { PriceValuePipe } from 'src/app/pipes/price-value.pipe';
import { IconBoxComponent } from 'src/app/components/_elements/icon-box/icon-box.component';
import { CurrencyPipe } from '@angular/common';
import { ViewContentComponent } from 'src/app/components/_layout/view-content/view-content.component';
import { ButtonComponent } from 'src/app/components/form/button/button.component';
import { OrderProductCardComponent } from 'src/app/views/protected/orders/order-product-card/order-product-card.component';

@Component({
  selector: 'completed-order-view',
  templateUrl: './completed-order.view.html',
  styleUrls: ['./completed-order.view.scss'],
  standalone: true,
  imports: [
    CardComponent,
    PriceValuePipe,
    IconBoxComponent,
    ViewContentComponent,
    ButtonComponent,
    RouterLink,
    OrderProductCardComponent,
  ],
  host: {
    id: 'view',
  },
  providers: [CurrencyPipe],
})
export class CompletedOrderView {
  @ViewChild('orderAllConfirmTemplate')
  orderAllConfirmTemplate: TemplateRef<any>;

  title = 'cards.store.cart.title';

  public formGroup: FormGroup;

  constructor(
    private _titleService: TitleService,
    public state: StoreState,
  ) {
    this.formGroup = new FormGroup({
      deliveryLocation: new FormControl(),
      contactPerson: new FormControl(),
      comment: new FormControl(),
    });
  }

  public data: any = [
    {
      orderItemId: '0bc2a6c9-d5f0-4ed1-5c29-08dc730a42d2',
      schemeId: 'c8426bc4-1020-49d5-d43f-08dc5dfbfe45',
      schemeType: 3,
      schemeValidFrom: '2024-05-10T00:00:00+03:00',
      schemeValidTo: '2024-05-13T23:59:59.999+03:00',
      productId: '3ff3bb46-2a7c-4746-05c2-08db2e894a3e',
      productFullNameTranslated: {
        et: 'Õun Jonagored 85+',
        en: null,
        ru: null,
      },
      productBrand: null,
      productEan: null,
      productDefaultSupplierCode: 'oupljr12',
      productRetailerCode: null,
      orderUnitId: '90b6021e-a4d3-4fda-2cac-08db2e894a3e',
      amount: 0,
      baseUnitBuyInPrice: 0.91,
      contentUnitId: '88d82609-4c55-42c9-85d7-a97984f66061',
      netContent: 1,
      drainedWeightInGrams: null,
      thumbnail:
        '/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wAARCAA3ADcDASIAAhEBAxEB/8QAHAAAAgIDAQEAAAAAAAAAAAAAAAgGCQIEBwUD/8QAMBAAAQMDAwIEBQMFAAAAAAAAAQIDBAAFEQYHIRIxCBNBgSJSYXGRMkJRFBVTcqH/xAAbAQACAgMBAAAAAAAAAAAAAAAABQQGAQMHAv/EACYRAAEEAgIABQUAAAAAAAAAAAEAAgMEETEFIQYSExRBUXGRocH/2gAMAwEAAhEDEQA/ALU6KKKEIqH6r3a0rot7yLrd2WZP+BALjg9eQkHHvUe8SO5r+1O1lwu0JIVdH1JhwhkDDq/3c/KkKV7Uk2kpVt1Qyt243AruoUp3zpHILmfi6lceuea0SSeTobVm4niPfNM0pIYDjrZT1WHffROoZzcKPem2JbqultqY2uOXD6BJWAFexNT/AL0h8OzPW7SF2vEyTFvRhKLibb0pCGelJKSB6kg5ye/wjGBTAeEfc+TuXtw+ZrinZVslKi9auSpvAUjJ9SASPYVGrWvXcWkdhHJcS2rD7iInGcEHGf0u40UUUwVZRWDrnlNqWQVBIzhIyT9q1b1d4tgtMy5TXAzDiMrfecPZKEgkn8Cly054orrrecl22WtEW0vqc8lx0EuBCf3K+/HGPWo01hkAHn+UxqUJ7ocYR03ZW14x7WdwtgI17tK1uxYzrVzSpIIJZU2pPVjvx5gJHpz/ABVb90v6oLiEKkCMykEqSlWCoEkLA7ds/k1YVq3X0vTWjJlohXVlURLBaS05HQ62EKB+AhWcg8j7Uh6diLnrS8l3Ucpu1sNufEzF6ulxHV8Kz3IJT6DP1I5ARvuNmlw0fHf3XUfDcL6kLmT6zkYU0d3MTqTbB22WpxbTshIHQg9KyBgD34Ap1/ApoKTozZNqXNBTJvMpc0BXcNfpRn7gE/YilR0d4a7C1blzYTkqRCSlTRXGkOIfZUcDrSFBQJ79xjt967R4eb/qXY/cm2aGvl3cv2kL8SzbJbyelcWQEFaW1jsCoAjjg8HjBFeOOcK8hjftxUTxIBZhLKx6B8xB31v8J0aKKKtC5QoTvZZX9RbSattsbqL8i2vJQEfqUeknA+pxiq44+4MjQ9uhx4wc6G45aCs4x/OEj2q1EjIIPaq8fFr4edaWPUEibovTMvVFjnOFxMSCjqeiqPKk9OcFH8EHPpjjJTclTNoNIGcK7eGr8FaR8NkgNd3k6XHHN4VTrpBLwBblSENr8zITyrjufqfSu36XhtTuuNdJiYtzlFxTL+evpPOASD/zt2pW7vsBvBeIsWPH2y1FEcWoBb8pjymmST+tRyTgZzkAn6UwNz0pM2FatU3VU1M2KuO0l+4sIPlpcwPMyjJUgdWeTwRjJB4CN3HSMjc5rVdbvIU5j6MMgyQdH+qR2AXuyNO26dd25kxAUl0sAobcSVqKSR8wT05r56Xvcu/bwaOta3EyW0XOMpQI+NsocStKxj/XHftn61C754odu4tvc/p9RW5SlZ4QsFZIHHbv610/wY/2rcXX72q1OKKYKOiG2WlZdUQR1n5QATgH1OfTnXQryyTtJGAEjsTNgqPLxoYH1JT2UUUVeVzBFYqbSvuAaKKELHyG/kH4rWn2WBdGCxMhR5TJ7tvNJWk+xFFFCF4iNrtHNuBxGlLIhY/cm3Mg/npr34dui25sNxY7UdA4CWkBIHsKKKFkknZWzRRRQsL/2Q==',
      thumbnailContentType: 'image/jpeg',
      imageUrl:
        'https://api-dev.lexi.market/api/Products/3ff3bb46-2a7c-4746-05c2-08db2e894a3e/images',
      imageThumbUrl:
        'https://api-dev.lexi.market/api/Products/3ff3bb46-2a7c-4746-05c2-08db2e894a3e/images/ThumbnailMedium',
      imageThumbSmallUrl:
        'https://api-dev.lexi.market/api/Products/3ff3bb46-2a7c-4746-05c2-08db2e894a3e/images/ThumbnailSmall',
      availableUnits: [
        {
          orderUnit: {
            translatedShortName: {
              et: 'kg',
              en: 'kg',
            },
            id: '88d82609-4c55-42c9-85d7-a97984f66061',
            value: 'Kilogramm',
            translatedValue: {
              et: 'Kilogramm',
              en: 'Kilogram',
              lv: 'Kilograms',
              lt: 'Kilogramas',
            },
            code: 'KGM',
          },
          contentUnit: {
            translatedShortName: {
              et: 'kg',
              en: 'kg',
            },
            id: '88d82609-4c55-42c9-85d7-a97984f66061',
            value: 'Kilogramm',
            translatedValue: {
              et: 'Kilogramm',
              en: 'Kilogram',
              lv: 'Kilograms',
              lt: 'Kilogramas',
            },
            code: 'KGM',
          },
          netContent: 1,
          id: '90b6021e-a4d3-4fda-2cac-08db2e894a3e',
          value: 'Jaepakend',
          translatedValue: {
            et: 'Jaepakend',
            en: 'Base unit',
          },
          baseUnitsCount: 1,
          typeId: '14ad41d7-5fbb-4af8-95d6-728cba4cc029',
          baseUnitId: '88d82609-4c55-42c9-85d7-a97984f66061',
        },
      ],
      requestedAmount: null,
      requestedBaseUnitBuyInPrice: null,
      requestedOrderUnitId: null,
      isReplacement: false,
      replacementForItemId: null,
      supplierId: '00000000-0000-0000-0000-000000000000',
      proposedAmount: null,
      proposedOrderUnitId: null,
      procurementItemId: 'fe150e4f-1531-4770-bd5c-08dc5dfb7d84',
      offerProductId: '63fcb568-2523-4758-28ca-08dc5dfbd653',
      assortmentStatus: {
        color: 1,
        statusRule: null,
        id: '7fa4070f-41a8-42f7-3a45-08dac8741009',
        value: 'Prioriteet C',
        translatedValue: null,
        code: 'PUJU_C',
      },
      assortmentGroups: [],
      hasCampaignPriceScheme: false,
      campaignPriceScheme: null,
    },
    {
      orderItemId: '50d2f274-d30f-402d-5c2a-08dc730a42d2',
      schemeId: '18ed69e0-e189-4670-d440-08dc5dfbfe45',
      schemeType: 3,
      schemeValidFrom: '2024-05-10T00:00:00+03:00',
      schemeValidTo: '2024-05-13T23:59:59.999+03:00',
      productId: '1f981c4c-67c5-40cb-9cb0-0911a8518024',
      productFullNameTranslated: {
        en: null,
        et: 'Õun Antei 60+ Kabala õunaaed',
        ru: null,
      },
      productBrand: null,
      productEan: null,
      productDefaultSupplierCode: 'oueean',
      productRetailerCode: null,
      orderUnitId: 'eba381ce-e8ea-43b5-8d73-07018ed22ad8',
      amount: 0,
      baseUnitBuyInPrice: 2.35,
      contentUnitId: '88d82609-4c55-42c9-85d7-a97984f66061',
      netContent: 1,
      drainedWeightInGrams: null,
      thumbnail: null,
      thumbnailContentType: null,
      imageUrl:
        'https://api-dev.lexi.market/api/Products/1f981c4c-67c5-40cb-9cb0-0911a8518024/images',
      imageThumbUrl:
        'https://api-dev.lexi.market/api/Products/1f981c4c-67c5-40cb-9cb0-0911a8518024/images/ThumbnailMedium',
      imageThumbSmallUrl:
        'https://api-dev.lexi.market/api/Products/1f981c4c-67c5-40cb-9cb0-0911a8518024/images/ThumbnailSmall',
      availableUnits: [
        {
          orderUnit: {
            translatedShortName: {
              et: 'kg',
              en: 'kg',
            },
            id: '88d82609-4c55-42c9-85d7-a97984f66061',
            value: 'Kilogramm',
            translatedValue: {
              et: 'Kilogramm',
              en: 'Kilogram',
              lv: 'Kilograms',
              lt: 'Kilogramas',
            },
            code: 'KGM',
          },
          contentUnit: {
            translatedShortName: {
              et: 'kg',
              en: 'kg',
            },
            id: '88d82609-4c55-42c9-85d7-a97984f66061',
            value: 'Kilogramm',
            translatedValue: {
              et: 'Kilogramm',
              en: 'Kilogram',
              lv: 'Kilograms',
              lt: 'Kilogramas',
            },
            code: 'KGM',
          },
          netContent: 1,
          id: 'eba381ce-e8ea-43b5-8d73-07018ed22ad8',
          value: 'Jaepakend',
          translatedValue: {
            et: 'Jaepakend',
            en: 'Base unit',
          },
          baseUnitsCount: 1,
          typeId: '14ad41d7-5fbb-4af8-95d6-728cba4cc029',
          baseUnitId: '88d82609-4c55-42c9-85d7-a97984f66061',
        },
      ],
      requestedAmount: null,
      requestedBaseUnitBuyInPrice: null,
      requestedOrderUnitId: null,
      isReplacement: false,
      replacementForItemId: null,
      supplierId: '00000000-0000-0000-0000-000000000000',
      proposedAmount: null,
      proposedOrderUnitId: null,
      procurementItemId: 'fe150e4f-1531-4770-bd5c-08dc5dfb7d84',
      offerProductId: '5de2ce47-c402-4841-28cb-08dc5dfbd653',
      assortmentStatus: {
        color: 2,
        statusRule: null,
        id: '36effc59-a63f-47b1-3a46-08dac8741009',
        value: 'Prioriteet B',
        translatedValue: null,
        code: 'PUJU_B',
      },
      assortmentGroups: [],
      hasCampaignPriceScheme: false,
      campaignPriceScheme: null,
    },
    {
      orderItemId: '2a6b5103-cd7e-4508-5c2b-08dc730a42d2',
      schemeId: 'ee6cafd8-f934-4b36-d441-08dc5dfbfe45',
      schemeType: 3,
      schemeValidFrom: '2024-05-10T00:00:00+03:00',
      schemeValidTo: '2024-05-13T23:59:59.999+03:00',
      productId: '20b83354-b94b-46fa-bae1-0a4f02a9dc3a',
      productFullNameTranslated: {
        en: null,
        et: 'Õun Golden 75+',
        ru: null,
      },
      productBrand: null,
      productEan: '',
      productDefaultSupplierCode: 'ogpl13o',
      productRetailerCode: null,
      orderUnitId: '06434d50-e98d-4cd0-8de7-bfd61d316530',
      amount: 0,
      baseUnitBuyInPrice: 1,
      contentUnitId: '88d82609-4c55-42c9-85d7-a97984f66061',
      netContent: 1,
      drainedWeightInGrams: null,
      thumbnail: null,
      thumbnailContentType: null,
      imageUrl:
        'https://api-dev.lexi.market/api/Products/20b83354-b94b-46fa-bae1-0a4f02a9dc3a/images',
      imageThumbUrl:
        'https://api-dev.lexi.market/api/Products/20b83354-b94b-46fa-bae1-0a4f02a9dc3a/images/ThumbnailMedium',
      imageThumbSmallUrl:
        'https://api-dev.lexi.market/api/Products/20b83354-b94b-46fa-bae1-0a4f02a9dc3a/images/ThumbnailSmall',
      availableUnits: [
        {
          orderUnit: {
            translatedShortName: {
              et: 'kg',
              en: 'kg',
            },
            id: '88d82609-4c55-42c9-85d7-a97984f66061',
            value: 'Kilogramm',
            translatedValue: {
              et: 'Kilogramm',
              en: 'Kilogram',
              lv: 'Kilograms',
              lt: 'Kilogramas',
            },
            code: 'KGM',
          },
          contentUnit: {
            translatedShortName: {
              et: 'kg',
              en: 'kg',
            },
            id: '88d82609-4c55-42c9-85d7-a97984f66061',
            value: 'Kilogramm',
            translatedValue: {
              et: 'Kilogramm',
              en: 'Kilogram',
              lv: 'Kilograms',
              lt: 'Kilogramas',
            },
            code: 'KGM',
          },
          netContent: 1,
          id: '06434d50-e98d-4cd0-8de7-bfd61d316530',
          value: 'Jaepakend',
          translatedValue: {
            et: 'Jaepakend',
            en: 'Base unit',
          },
          baseUnitsCount: 1,
          typeId: '14ad41d7-5fbb-4af8-95d6-728cba4cc029',
          baseUnitId: '88d82609-4c55-42c9-85d7-a97984f66061',
        },
      ],
      requestedAmount: null,
      requestedBaseUnitBuyInPrice: null,
      requestedOrderUnitId: null,
      isReplacement: false,
      replacementForItemId: null,
      supplierId: '00000000-0000-0000-0000-000000000000',
      proposedAmount: null,
      proposedOrderUnitId: null,
      procurementItemId: 'fe150e4f-1531-4770-bd5c-08dc5dfb7d84',
      offerProductId: 'f4f91331-aa77-4ce1-28cc-08dc5dfbd653',
      assortmentStatus: {
        color: 3,
        statusRule: null,
        id: '75d80e9d-991d-4317-3a47-08dac8741009',
        value: 'Prioriteet A',
        translatedValue: null,
        code: 'PUJU_A',
      },
      assortmentGroups: [],
      hasCampaignPriceScheme: false,
      campaignPriceScheme: null,
    },
    {
      orderItemId: 'cc45ff5e-576c-40d1-5c2c-08dc730a42d2',
      schemeId: 'e030f707-75ae-44b1-d442-08dc5dfbfe45',
      schemeType: 3,
      schemeValidFrom: '2024-05-10T00:00:00+03:00',
      schemeValidTo: '2024-05-13T23:59:59.999+03:00',
      productId: 'ee2d7fb1-e4d5-478a-96a1-26b6f99351e4',
      productFullNameTranslated: {
        en: null,
        et: 'Pirn Packham 90-112',
        ru: null,
      },
      productBrand: null,
      productEan: '',
      productDefaultSupplierCode: 'pizap12v',
      productRetailerCode: 'T000000059',
      orderUnitId: '4e63cb4f-4e60-457f-826a-a209f191ce12',
      amount: 0,
      baseUnitBuyInPrice: 1,
      contentUnitId: '88d82609-4c55-42c9-85d7-a97984f66061',
      netContent: 1,
      drainedWeightInGrams: null,
      thumbnail: null,
      thumbnailContentType: null,
      imageUrl:
        'https://api-dev.lexi.market/api/Products/ee2d7fb1-e4d5-478a-96a1-26b6f99351e4/images',
      imageThumbUrl:
        'https://api-dev.lexi.market/api/Products/ee2d7fb1-e4d5-478a-96a1-26b6f99351e4/images/ThumbnailMedium',
      imageThumbSmallUrl:
        'https://api-dev.lexi.market/api/Products/ee2d7fb1-e4d5-478a-96a1-26b6f99351e4/images/ThumbnailSmall',
      availableUnits: [
        {
          orderUnit: {
            translatedShortName: {
              et: 'kg',
              en: 'kg',
            },
            id: '88d82609-4c55-42c9-85d7-a97984f66061',
            value: 'Kilogramm',
            translatedValue: {
              et: 'Kilogramm',
              en: 'Kilogram',
              lv: 'Kilograms',
              lt: 'Kilogramas',
            },
            code: 'KGM',
          },
          contentUnit: {
            translatedShortName: {
              et: 'kg',
              en: 'kg',
            },
            id: '88d82609-4c55-42c9-85d7-a97984f66061',
            value: 'Kilogramm',
            translatedValue: {
              et: 'Kilogramm',
              en: 'Kilogram',
              lv: 'Kilograms',
              lt: 'Kilogramas',
            },
            code: 'KGM',
          },
          netContent: 1,
          id: '4e63cb4f-4e60-457f-826a-a209f191ce12',
          value: 'Jaepakend',
          translatedValue: {
            et: 'Jaepakend',
            en: 'Base unit',
          },
          baseUnitsCount: 1,
          typeId: '14ad41d7-5fbb-4af8-95d6-728cba4cc029',
          baseUnitId: '88d82609-4c55-42c9-85d7-a97984f66061',
        },
      ],
      requestedAmount: null,
      requestedBaseUnitBuyInPrice: null,
      requestedOrderUnitId: null,
      isReplacement: false,
      replacementForItemId: null,
      supplierId: '00000000-0000-0000-0000-000000000000',
      proposedAmount: null,
      proposedOrderUnitId: null,
      procurementItemId: 'aecc45cc-f862-4dca-bd5e-08dc5dfb7d84',
      offerProductId: 'fbb89054-b52a-4010-28cd-08dc5dfbd653',
      assortmentStatus: {
        color: 1,
        statusRule: null,
        id: '7fa4070f-41a8-42f7-3a45-08dac8741009',
        value: 'Prioriteet C',
        translatedValue: null,
        code: 'PUJU_C',
      },
      assortmentGroups: [],
      hasCampaignPriceScheme: false,
      campaignPriceScheme: null,
    },
  ];

  public items = [
    {
      orderName: 'Tellimus PO-BRIGH-2408000002',
      supplier: 'Maripuu Viljad AS',
      products: this.data,
    },
    {
      orderName: 'Tellimus PO-BRIGH-2408000003',
      supplier: 'Tammeorg Viljad AS',
      products: this.data,
    },
  ];

  public ngOnInit() {
    this._titleService.setTitle(this.title);
  }
}
