import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class StoreSearchData {
  public searchData = new FormGroup({
    searchTerm: new FormControl(),
    brands: new FormControl(),
    subBrands: new FormControl(),
    countries: new FormControl(),
    suppliers: new FormControl(),
    gs1Segments: new FormControl(),
    assortmentStatuses: new FormControl(),
    productAttributes: new FormControl(),
    shouldShowEndOfLifeProducts: new FormControl(),
    nutritionalClaims: new FormControl(),
  });

  public serializeSearchData() {
    const searchData = this.searchData.value;
    return {
      searchTerm: searchData.searchTerm,
      brandIds: searchData.brands?.map((x: any) => x.id),
      subBrandIds: searchData.subBrands?.map((x: any) => x.id),
      countryIds: searchData.countries,
      supplierIds: searchData.suppliers?.map((x: any) => x.id),
      gs1SegmentIds: searchData.gs1Segments?.map((x: any) => x.id),
      assortmentStatuses: searchData.assortmentStatuses,
      productAttributeIds: searchData.productAttributes?.map((x: any) => x.id),
      shouldShowEndOfLifeProducts: searchData.shouldShowEndOfLifeProducts,
      nutritionalClaims: searchData.nutritionalClaims,
    };
  }
}
