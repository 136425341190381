import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.startsWith('api/')) {
    let data = {
      url: environment.apiUrl + req.url,
      withCredentials: true,
    } as any;

    req = req.clone(data);
  }

  return next(req);
};
