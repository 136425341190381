<view-content bgColor="var(--app-bg-secondary)">
  <div class="flex flex-grow flex-col gap-5x">
    <div class="flex flex-col gap-5x">
      <h1 class="font-display font-bold text-grey-100">Täname ostu eest!</h1>

      <div class="flex flex-col font-text text-sm font-normal text-grey-100">
        <span>
          Tellimus on edastatud tarnijatele ja hetkel ootab kinnitust.
        </span>

        <span>
          Kui tarnijad on tellimuse kinnitanud, saad sellest teade kätte e-maili
          <a class="font-bold text-tangerine" href="mailto:martin@ails.ee">
            martin&#64;alis.ee
          </a>
        </span>
      </div>

      <div class="flex flex-col gap-5x py-5x">
        <button
          appButton
          color="primary"
          size="big"
          label="Jätka ostlemist"
          routerLink="/"
        ></button>
        <button
          appButton
          color="transparent"
          size="big"
          label="Vaata oma tellimusi"
          routerLink="/orders"
        ></button>
      </div>
    </div>

    <div
      class="grid-table !grid !grid-cols-[repeat(auto-fit,minmax(21rem,1fr))] gap-5x"
    >
      <div class="card !flex-none p-6x">
        <div class="flex flex-col gap-[0.9375rem]">
          <h3>Tarne asukoht</h3>
          <div class="flex flex-col gap-2-5x">
            <div class="flex items-center gap-5x">
              <icon-box icon="monochromeAddress"></icon-box>
              <span class="bold"> Harkujärve tankla </span>
            </div>
            <div class="ml-17x flex flex-col">
              <span> Mustakivi tee 17, 13912 Tallinn </span>
            </div>
          </div>
        </div>
      </div>

      <div class="card !flex-none p-6x">
        <div class="flex flex-col gap-[0.9375rem]">
          <h3>Kontaktisik</h3>
          <div class="flex flex-col gap-2-5x">
            <div class="flex items-center gap-5x">
              <icon-box icon="monochromeUser"></icon-box>
              <span class="bold"> Mari Kask </span>
            </div>
            <div class="ml-17x flex items-center gap-5x">
              <a href="tel:+3725854222"> +372 585 4222 </a>
              <a
                href="mailto:martin@alis.ee"
                class="font-text text-sm font-medium"
              >
                martin&#64;alis.ee
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="card !flex-none p-6x">
        <div class="flex w-full flex-col gap-[0.9375rem]">
          <h3>Tellimus kokku</h3>
          <div class="flex flex-col gap-2-5x border-b border-grey-40 pb-5x">
            <div class="flex justify-between">
              <span class="font-text text-sm font-normal text-grey-100"
                >Ilma käibemaksuta</span
              >
              <span class="font-display text-sm font-bold text-grey-100"
                >{{6.430 | priceValue}}</span
              >
            </div>
            <div class="flex justify-between">
              <span class="font-text text-sm font-normal text-grey-100"
                >Käibemaks</span
              >
              <span class="font-display text-sm font-bold text-grey-100"
                >{{1.820 | priceValue}}</span
              >
            </div>
          </div>
          <div class="flex justify-between">
            <span class="font-text text-sm font-normal text-grey-100"
              >Kokku
            </span>
            <span class="font-display text-base font-bold text-grey-100"
              >{{8.250 | priceValue}}
            </span>
          </div>
        </div>
      </div>
    </div>

    @for(item of items; track $index) {
    <div class="card">
      <div class="flex flex-col gap-2x">
        <span class="font-display text-xs font-medium text-grey-100">
          {{item.supplier}}
        </span>
        <h3>
          <span class="text-rustyOrange"> {{item.supplier}} </span>
          <span>&nbsp;tooted</span>
          <span>&nbsp;({{item.products.length}})</span>
        </h3>
      </div>

      @for(product of data; track $index) {
      <order-product-card
        [data]="product"
        [isFinalized]="true"
      ></order-product-card>
      }
    </div>
    }

    <!-- 
    <cart-products-list-card
      *ngFor="let item of data.controls"
      class="h-max !flex-none"
      [formGroup]="formGroup"
      [data]="item"
      [isFinalized]="true"
    ></cart-products-list-card> -->
  </div>
</view-content>
