import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, RouterLink } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { HeaderComponent } from 'src/app/components/_layout/app-header/header.component';
import { NavigatorComponent } from 'src/app/components/_layout/app-navigator/navigator.component';

@Component({
  selector: 'protected-layout',
  templateUrl: './protected.layout.html',
  styleUrls: ['./protected.layout.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    NavigatorComponent,
    CommonModule,
    SvgIconComponent,
    HeaderComponent,
  ],
  host: {
    id: 'layout',
  },
})
export class ProtectedLayout implements OnInit {
  constructor() {}

  ngOnInit() {}
}
