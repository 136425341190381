import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { UserAction, LxmAppModule } from 'src/app/enum';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ILanguage, LocaleService } from 'src/app/services/locale.service';
import { OverlayViewService } from 'src/app/services/overlay-view.service';
import { AppState } from 'src/app/state/app.state';
import { SelectComponent } from '../../form/select/select.component';
import {
  JsonPipe,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { OverlayViewMenuItemComponent } from '../overlay-view/menu-item/ov-menu-item.component';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    SvgIconComponent,
    RouterLink,
    ReactiveFormsModule,
    SelectComponent,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgTemplateOutlet,
    JsonPipe,
    OverlayViewMenuItemComponent,
    MatRipple,
  ],
  host: {
    class: 'sticky left-0 right-0 top-0 px-8x w-full z-[1000] bg-inherit',
  },
})
export class HeaderComponent {
  @ViewChild('helpHeaderTemplate', { static: false })
  helpHeaderTemplate?: ElementRef;
  @ViewChild('helpContentTemplate', { static: false })
  helpContentTemplate?: ElementRef;

  @ViewChild('userHeaderTemplate', { static: false })
  userHeaderTemplate?: ElementRef;
  @ViewChild('userContentTemplate', { static: false })
  userContentTemplate?: ElementRef;

  public UserAction = UserAction;
  public LxmAppModule = LxmAppModule;

  public form: FormGroup;

  public showTenantsFilter = false;
  public tenants = [];

  constructor(
    public overlayViewService: OverlayViewService,
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _router: Router,
    public appState: AppState,
    public locale: LocaleService,
  ) {}

  public switchTenant(tenantId: string) {
    this._authenticationService.switchTenant(tenantId);
  }

  public get tenantId() {
    return this._authenticationService.currentTenantId.value;
  }

  public languageValueCompare(v1: ILanguage, v2: ILanguage) {
    return v1.id === v2.id;
  }

  public changeLanguage(selection: ILanguage) {
    this.locale.setLanguage(selection);
    this._userService.setPreferredLanguage(selection.id).subscribe();
  }

  public get languageOptions() {
    const uiLanguages = this.appState.userSettings?.uiLanguages;
    if (uiLanguages?.length > 0) {
      return uiLanguages;
    }
    return this.locale.languageOptions;
  }

  public getLanguageIcon(languageId) {
    switch (languageId) {
      case 1:
        return 'est';
      case 2:
        return 'gbr';
      case 32: // TAL/Tallink
        return 'tallink';
      default:
        return 'global';
    }
  }

  public actionItems = [
    {
      title: 'Help',
      icon: 'menu-question.svg',
      function: () => {
        this.overlayViewService.add({
          headerRef: this.helpHeaderTemplate,
          contentRef: this.helpContentTemplate,
          id: 'help',
        });
      },
    },
    {
      title: 'User',
      icon: 'menu-user.svg',
      function: () => {
        this.overlayViewService.add({
          headerRef: this.userHeaderTemplate,
          contentRef: this.userContentTemplate,
          id: 'user-menu',
          animation: 'bottom-to-top',
        });
      },
    },
  ];

  public visitorActionItems = [
    {
      title: 'Help',
      icon: 'menu-question.svg',
      function: () => {
        this.overlayViewService.add({
          headerRef: this.helpHeaderTemplate,
          contentRef: this.helpContentTemplate,
          id: 'help',
        });
      },
    },
  ];

  ngOnInit() {
    const prop = 'name';
    const tenants = this.appState.userSettings?.tenants?.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1,
    );
    this.form = new FormGroup({
      tenant: new FormControl(this.tenantId),
      language: new FormControl(this.appState.currentLanguage),
    });

    this.tenants = tenants;
    this.showTenantsFilter = tenants?.length > 1;
  }

  public logout() {
    this._authenticationService.logout();
    this._router.navigate(['/login']);
    this.overlayViewService.clear();
  }
}
