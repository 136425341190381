<nav
  class="nav-wrapper inherit flex h-14 flex-1 items-center justify-center gap-4x rounded-tl-3xl rounded-tr-3xl px-8x py-0"
>
  @let linkClasses =
    "group flex h-14 flex-1 flex-col items-center justify-center font-display gap-5x text-ellipsis whitespace-nowrap border-b-[0.188rem] border-b-[transparent] pt-2x [&.active]:border-b-tangerine cursor-pointer";

  @for (item of menuItems; track item.label) {
    <!-- LINK -->

    @if (item.function) {
      <a [class]="linkClasses" (click)="item.function()" matRipple>
        <ng-container
          *ngTemplateOutlet="linkContentTemplate; context: { item: item }"
        ></ng-container>
      </a>
    } @else {
      <a
        [class]="linkClasses"
        [routerLink]="item.route"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="
          item.routerLinkActiveOptions ?? { exact: false }
        "
        matRipple
      >
        <ng-container
          *ngTemplateOutlet="linkContentTemplate; context: { item: item }"
        ></ng-container>
      </a>
    }
  }
</nav>

<!-- LINK CONTENT TEMPLATE -->
<ng-template #linkContentTemplate let-item="item">
  <!-- LINK ICON -->
  <svg-icon
    [src]="item.icon"
    [svgStyle]="{ width: 'var(--9x)', height: 'var(--9x)' }"
    viewBox="auto"
  ></svg-icon>

  <!-- LINK LABEL -->
  <span
    class="text-center text-[0.625rem] font-medium tracking-wide text-grey-100 group-[&.active]:font-bold"
  >
    {{ item.label }}
  </span>
</ng-template>

<!-- OVERLAY TEMPLATES -->
@let iconClasses = "flex size-15x items-center justify-center";

<!-- SEARCH TEMPLATES -->
<ng-template #searchHeaderTemplate>
  <!-- SEARCH ICON -->
  <div [class]="iconClasses">
    <svg-icon src="assets/icons/menu/menu-search.svg"></svg-icon>
  </div>
  <!-- SEARCH INPUT -->
  <input
    class="flex flex-1 font-display text-sm font-bold text-grey-100 outline-none"
    placeholder="Otsi"
    autofocus
  />
</ng-template>
<ng-template #searchContentTemplate> ...</ng-template>
<!--  -->

<!-- SHOW MORE TEMPLATE -->
<ng-template #showMoreHeaderTemplate>
  <!-- SEARCH ICON -->
  <div [class]="iconClasses">
    <svg-icon src="assets/icons/menu/menu-dots.svg"></svg-icon>
  </div>
  <!-- TEXT -->
  <span class="m-auto font-display text-sm font-bold text-grey-100"> Veel</span>
</ng-template>
<ng-template #showMoreContentTemplate>
  <div class="py-4x">
    <a
      ov-menu-item
      label="Asukohad"
      icon="/assets/icons/menu/menu-locations.svg"
      routerLink="/locations"
      routerLinkActive="active"
    ></a>
    <a
      ov-menu-item
      label="Salvestatud ostukorvid"
      icon="/assets/icons/menu/menu-saved-carts.svg"
      routerLink="/saved"
      routerLinkActive="active"
    ></a>
    <!-- <a
      ov-menu-item
      label="Lemmikud"
      icon="/assets/icons/menu/menu-favorites.svg"
      routerLink="/favorites"
      routerLinkActive="active"
    ></a> -->
    <a
      ov-menu-item
      label="Stylesheet (dev)"
      routerLink="/stylesheet"
      routerLinkActive="active"
      icon="/assets/icons/menu/menu-favorites.svg"
    ></a>
  </div>
</ng-template>
<!--  -->
