import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'register-view',
  host: { class: 'view' },
  templateUrl: './register.view.html',
  styleUrls: ['./register.view.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class RegisterView {}
