<div class="bg-appBg flex flex-1 flex-col">
  <!-- HEADER -->
  <app-header class="sticky left-0 right-0 top-0 px-8x"></app-header>

  <!-- CONTENT OUTLET - Component should have class 'view' -->
  <router-outlet></router-outlet>

  <!-- NAVIGATOR -->
  <app-navigator class="sticky bottom-0 left-0 right-0"></app-navigator>
</div>
