import { NgTemplateOutlet } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { AutofocusDirective } from 'src/app/directives/autofocus.directive';
import { OverlayViewService } from 'src/app/services/overlay-view.service';
import { OverlayViewMenuItemComponent } from '../overlay-view/menu-item/ov-menu-item.component';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-navigator',
  standalone: true,
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss'],
  imports: [
    RouterLink,
    RouterLinkActive,
    SvgIconComponent,
    NgTemplateOutlet,
    AutofocusDirective,
    OverlayViewMenuItemComponent,
    MatRippleModule,
  ],
  host: {
    class: 'z-50 bg-white',
  },
})
export class NavigatorComponent implements OnInit {
  @ViewChild('searchHeaderTemplate', { static: false })
  searchHeaderTemplate?: ElementRef;
  @ViewChild('searchContentTemplate', { static: false })
  searchContentTemplate?: ElementRef;

  @ViewChild('showMoreHeaderTemplate', { static: false })
  showMoreHeaderTemplate?: ElementRef;
  @ViewChild('showMoreContentTemplate', { static: false })
  showMoreContentTemplate?: ElementRef;

  constructor(public overlayViewService: OverlayViewService) {}

  ngOnInit() {}

  public iconsRoot = 'assets/icons/menu/';

  public menuItems = [
    {
      label: 'E-pood',
      icon: 'menuEStore',
      iconActive: 'menuEStoreActive',
      route: '/',
      routerLinkActiveOptions: { exact: true },
    },
    {
      label: 'Tellimused',
      icon: 'menuOrders',
      iconActive: 'menuOrdersActive',
      route: '/orders',
    },
    {
      label: 'Otsi',
      icon: 'menuSearch',
      iconActive: 'menuSearchActive',
      function: () => {
        this.overlayViewService.add({
          headerRef: this.searchHeaderTemplate,
          contentRef: this.searchContentTemplate,
          id: 'search',
          headerBorder: true,
        });
      },
    },
    {
      label: 'Ostukorv',
      icon: 'menuCart',
      iconActive: 'menuCartActive',
      route: '/cart',
    },
    {
      label: 'Veel',
      icon: 'menuDots',
      iconActive: 'menuDotsActive',
      route: null,
      function: () => {
        this.overlayViewService.add({
          headerIcon: 'menuDots',
          headerLabel: 'veel',
          contentRef: this.showMoreContentTemplate,
          id: 'show-more',
        });
      },
    },
  ];
}
