import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from 'src/app/components/_layout/app-header/header.component';
import { ViewContentComponent } from 'src/app/components/_layout/view-content/view-content.component';

@Component({
  selector: 'public-layout',
  templateUrl: './public.layout.html',
  styleUrls: ['./public.layout.scss'],
  standalone: true,
  imports: [RouterOutlet, ViewContentComponent, HeaderComponent],
  host: {
    id: 'layout',
  },
})
export class PublicLayout implements OnInit {
  ngOnInit() {}
}
