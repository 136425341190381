import { ActivatedRouteSnapshot, Resolve, Routes } from '@angular/router';
import { Injectable } from '@angular/core';
import { ILanguage, LocaleService } from './services/locale.service';
import { AppState } from './state/app.state';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { NotFoundViewComponent } from './views/public/not-found/not-found.component';
import { IUserSettings, IUserState } from './models/IUserSettings';
import { PublicLayout } from './views/public/public.layout';
import { ProtectedLayout } from './views/protected/protected.layout';
import { AuthGuard } from './guards/auth.guard';
import { LoginView } from './views/public/login/login.view';
import { RegisterView } from './views/public/register/register.view';
import { locationsListResolver } from './views/protected/locations/locations.view';
import { locationResolver } from './views/protected/locations/location/location.view';
import { userProfileResolver } from './views/protected/user/settings/user-settings.view';
import { purchaseOrdersListResolver } from './views/protected/orders/purchase/list/purchase-orders.view';
import {
  purchaseOrderFormDataResolver,
  purchaseOrderResolver,
} from './views/protected/orders/purchase/order/purchase-order.view';

@Injectable()
export class UserSettingsResolve implements Resolve<any> {
  constructor(
    private _appState: AppState,
    private _http: HttpClient,
    // private _signalRService: SignalRService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IUserSettings> {
    // this._signalRService.commonHub
    //   .subscribe(connection => {
    //     if (connection) {
    //       if (connection.state === HubConnectionState.Disconnected) {
    //         connection.start();
    //       }
    //     }
    //   });

    if (this._appState.userSettings) {
      return new Observable<IUserSettings>((subscriber) => {
        subscriber.next(this._appState.userSettings);
        subscriber.complete();
      });
    }

    return this._http.get<IUserState>(`api/user/state`).pipe(
      map((res) => {
        this._appState.inbox = res.inbox;
        this._appState.userSettings = res.settings;

        let appLanguage: ILanguage;
        const uiLanguages = res.settings.uiLanguages;
        const preferredUiLanguage = res.settings.preferredUiLanguage;
        const backupUiLanguage = res.settings.uiLanguages[0];

        if (
          preferredUiLanguage &&
          uiLanguages.find((x) => x.id === preferredUiLanguage.id)
        ) {
          appLanguage = preferredUiLanguage;
        } else {
          appLanguage = backupUiLanguage;
        }

        this._appState.initializeApp(appLanguage);

        return res.settings;
      }),
    );
  }
}

@Injectable()
export class GuestSettingsResolve implements Resolve<any> {
  constructor(
    private _appState: AppState,
    private _localeService: LocaleService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    this._appState.initializeApp(this._localeService.getGuestLanguage());
  }
}

export const routes: Routes = [
  {
    path: '',
    component: ProtectedLayout,
    canActivate: [AuthGuard],
    resolve: {
      userSettings: UserSettingsResolve,
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./views/protected/e-store/e-store.component').then(
            (c) => c.EStoreViewComponent,
          ),
        title: 'E-pood',
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./views/protected/orders/orders.view').then(
                (c) => c.OrdersViewComponent,
              ),
            title: 'Tellimused',
            children: [
              {
                path: '',
                redirectTo: 'purchase',
                pathMatch: 'full',
              },
              {
                path: 'purchase',
                children: [
                  {
                    path: '',
                    loadComponent: () =>
                      import(
                        './views/protected/orders/purchase/list/purchase-orders.view'
                      ).then((c) => c.PurchaseOrdersListComponent),
                    resolve: {
                      data: purchaseOrdersListResolver,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'purchase/:orderId',
            loadComponent: () =>
              import(
                './views/protected/orders/purchase/order/purchase-order.view'
              ).then((c) => c.PurchaseOrderComponent),
            resolve: {
              data: purchaseOrderResolver,
              formData: purchaseOrderFormDataResolver,
            },
          },

          // {
          //   path: 'sales',
          //   loadComponent: () =>
          //     import('./views/protected/orders/sales/sales-orders.view').then(
          //       (c) => c.SalesOrdersViewComponent,
          //     ),
          // },
        ],
      },
      {
        path: 'cart',
        loadComponent: () =>
          import('./views/protected/carts/cart/cart.component').then(
            (c) => c.CartViewComponent,
          ),
        title: 'Ostukorv',
      },
      {
        path: 'saved',
        loadComponent: () =>
          import('./views/protected/carts/saved/saved-carts.view').then(
            (c) => c.SavedCartsViewComponent,
          ),
        title: 'Salvestatud ostukorvid',
      },

      {
        path: 'locations',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./views/protected/locations/locations.view').then(
                (c) => c.LocationsViewComponent,
              ),
            resolve: {
              locations: locationsListResolver,
            },
            title: 'Asukohad',
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./views/protected/locations/location/location.view').then(
                (c) => c.LocationViewComponent,
              ),
            resolve: {
              data: locationResolver,
            },
          },
        ],
      },
      {
        path: 'stylesheet',
        loadComponent: () =>
          import('./views/protected/stylesheet(dev)/stylesheet.view').then(
            (c) => c.StylesheetViewComponent,
          ),
        title: 'Stylesheet',
      },
      {
        path: 'favorites',
        loadComponent: () =>
          import('./views/protected/favorites/favorites.view').then(
            (c) => c.FavoritesViewComponent,
          ),
        title: 'Lemmikud',
      },
      {
        path: 'user',
        loadComponent: () =>
          import('./views/protected/user/user.view').then((c) => c.UserView),
        children: [
          {
            path: '',
            redirectTo: 'settings',
            pathMatch: 'full',
          },
          {
            path: 'settings',
            loadComponent: () =>
              import('./views/protected/user/settings/user-settings.view').then(
                (c) => c.UserSettingsView,
              ),
            title: 'Minu profiil',
            resolve: {
              data: userProfileResolver,
            },
          },
          {
            path: 'password',
            loadComponent: () =>
              import('./views/protected/user/password/user-password.view').then(
                (c) => c.UserPasswordView,
              ),
            title: 'Minu parool',
          },
        ],
      },
      // {
      //   path: '**',
      //   component: NotFoundViewComponent,
      // },
    ],
  },
  // Routes which use the guest layout:
  {
    path: '',
    component: PublicLayout,
    // resolve: {
    //   _: GuestSettingsResolve,
    // },
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        children: [
          {
            path: '',
            component: LoginView,
          },
          {
            path: 'idcard/:sessionId',
            component: LoginView,
          },
          {
            path: 'azureAd/:sessionId',
            component: LoginView,
          },
          {
            path: 'selver',
            component: LoginView,
            data: {
              customFor: 'selver',
            },
          },
        ],
      },
      {
        path: 'register',
        component: RegisterView,
      },

      { path: 'logout', component: LoginView },
      {
        path: '**',
        component: NotFoundViewComponent,
      },
    ],
  },
];
